import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import examService from "services/ExamService";

export const createExam = createAsyncThunk('exam/createExam', async (data, { rejectWithValue }) => {
    const { title, description, imgUrl } = data
	try {
		const response = await examService.createExam({ title, description, imgUrl})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getExams = createAsyncThunk('exam/getExams', async (_, { rejectWithValue }) => {
	try {
		const response = await examService.getExams()
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getExamSessions = createAsyncThunk('exam/getExamSessions', async (data, { rejectWithValue }) => {
    const { examId } = data
	try {
		const response = await examService.getExamSessions({examId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createSubject = createAsyncThunk('exam/createSubject', async (data, { rejectWithValue }) => {
    const { examId, title } = data
	try {
		const response = await examService.createExamSubject({ title, examId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createTopic = createAsyncThunk('exam/createTopic', async (data, { rejectWithValue }) => {
    const { examId, subjectId, title } = data
	try {
		const response = await examService.createExamTopic({ title, examId, subjectId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
    exams: {},
    examSessions: {},
    status: 'idle',
    error: null
}

export const examSlice = createSlice({
    name: 'exam',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(createExam.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(createExam.fulfilled, (state, action) => {
            state.status = 'success'
        })
        .addCase(createExam.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getExams.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getExams.fulfilled, (state, action) => {
            state.status = 'success'
            state.exams = action.payload
        })
        .addCase(getExams.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getExamSessions.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getExamSessions.fulfilled, (state, action) => {
            state.status = 'success'
            state.examSessions = action.payload
        })
        .addCase(getExamSessions.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
    }

})

export default examSlice.reducer

export const selectAllExam = state => state.exam.exams
export const selectExamStatus = state => state.exam.status
export const selectExamById = (state, examId) => state.exam.exams.exams.find(exam => examId === exam.id)
export const selectExamSessions = (state) => {
    let sessions = state.exam.examSessions;
    return sessions
}
