import fetch from 'auth/FetchInterceptor'


const fileService = {}

fileService.uploadFile = function (data) {
    const formData = new FormData();
    formData.append('file', data.file.originFileObj);
    return fetch({
      url: `/file/images`,
      method: 'post',
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
}

export default fileService